<template>
  <v-overlay
    :absolute="true"
    :opacity="0.8"
    :value="!onLine"
    :z-index="100"
  />
</template>
<script>
export default {
  data() {
    return {
      onLine: navigator.onLine,
    };
  },
  mounted() {
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleNetworkChange);
    window.removeEventListener('offline', this.handleNetworkChange);
  },
  methods: {
    handleNetworkChange(event) {
      const { type } = event;
      this.onLine = type === 'online';
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.$notify.success(this.$t('connectivity.online'));
        return;
      }
      this.$notify.error(this.$t('connectivity.offline'), { hide: -1 });
    },
  },
};
</script>
